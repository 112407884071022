import {
  fetchHierarchy,
  createCuratorGroup as createCuratorGroupAPI,
  createCuratorGroupFromInstagramZIP as createCuratorGroupFromInstagramZIPAPI,
  createCuratorGroupFromTasteProfile as createCuratorGroupFromTasteProfileAPI,
  duplicateCuratorGroup as duplicateCuratorGroupAPI,
  updateCuratorGroup as updateCuratorGroupAPI,
  deleteCuratorGroup as deleteCuratorGroupAPI,
  createCuratorGroupUser as createCuratorGroupUserAPI,
  updateCuratorGroupUser as updateCuratorGroupUserAPI,
  deleteCuratorGroupUser as deleteCuratorGroupUserAPI,
  createCuratorGroupFavorite as createCuratorGroupFavoriteAPI,
  deleteCuratorGroupFavorite as deleteCuratorGroupFavoriteAPI,
  getCuratorsAndGroupsViaDiscover as getCuratorsAndGroupsViaDiscoverAPI,
  createUserProduct as createUserProductAPI,
  updateUserProduct as updateUserProductAPI,
  deleteUserProduct as deleteUserProductAPI
} from '../APIClient/shop';
import { createPin as createPinAPI } from '../APIClient/pins';
import { getProduct as getProductAPI, getSimilarProducts as getSimilarProductsAPI } from '../APIClient/products';
import {
  getSimpleCollectionsForAdding as getSimpleCollectionsForAddingAPI,
  deleteProductFromCollection as deleteProductFromCollectionAPI
} from '../APIClient/collections';
import { withShopperLoginCheck } from './UIActions';
import { getAttributableCuratorId, getAttributableCuratorGroupId } from '../Helpers/shop_helpers';
import { getUserId, getCuratorGroups } from '../Helpers/user_helpers';
import moment from 'moment';
import _ from 'lodash';

export const GET_SHOP_HIERARCHY_SUCCESS = 'GET_SHOP_HIERARCHY_SUCCESS';

// Home Page
export const GET_FEATURED_CURATOR_GROUPS_SUCCESS = 'GET_FEATURED_CURATOR_GROUPS_SUCCESS';
export const GET_FEATURED_CURATOR_GROUPS_ERROR = 'GET_FEATURED_CURATOR_GROUPS_ERROR';

// Shop Level
export const SET_ACTIVE_CURATOR = 'SET_ACTIVE_CURATOR';
export const SET_ACTIVE_CURATOR_GROUP = 'SET_ACTIVE_CURATOR_GROUP';

export const SET_ATTRIBUTABLE_CURATOR_ID = 'SET_ATTRIBUTABLE_CURATOR_ID';
export const SET_ATTRIBUTABLE_CURATOR_GROUP_ID = 'SET_ATTRIBUTABLE_CURATOR_GROUP_ID';

export const ADD_RECENTLY_VIEWED_PRODUCT = 'ADD_RECENTLY_VIEWED_PRODUCT';

// Curator Groups
export const CREATE_CURATOR_GROUP_SUCCESS = 'CREATE_CURATOR_GROUP_SUCCESS';
export const CREATE_CURATOR_GROUP_ERROR = 'CREATE_CURATOR_GROUP_ERROR';
export const CREATE_CURATOR_GROUP_VIA_INSTAGRAM_ZIP_ERROR = 'CREATE_CURATOR_GROUP_VIA_INSTAGRAM_ZIP_ERROR';

export const DUPLICATE_CURATOR_GROUP_SUCCESS = 'DUPLICATE_CURATOR_GROUP_SUCCESS';
export const DUPLICATE_CURATOR_GROUP_ERROR = 'DUPLICATE_CURATOR_GROUP_ERROR';

export const UPDATE_CURATOR_GROUP_REQUEST = 'UPDATE_CURATOR_GROUP_REQUEST';
export const UPDATE_CURATOR_GROUP_SUCCESS = 'UPDATE_CURATOR_GROUP_SUCCESS';
export const UPDATE_CURATOR_GROUP_ERROR = 'UPDATE_CURATOR_GROUP_ERROR';

export const DELETE_CURATOR_GROUP_REQUEST = 'DELETE_CURATOR_GROUP_REQUEST';
export const DELETE_CURATOR_GROUP_SUCCESS = 'DELETE_CURATOR_GROUP_SUCCESS';
export const DELETE_CURATOR_GROUP_ERROR = 'DELETE_CURATOR_GROUP_ERROR';

// Curators Group Users
export const CREATE_CURATOR_GROUP_USER_SUCCESS = 'CREATE_CURATOR_GROUP_USER_SUCCESS';
export const CREATE_CURATOR_GROUP_USER_ERROR = 'CREATE_CURATOR_GROUP_USER_ERROR';

export const UPDATE_CURATOR_GROUP_USER_REQUEST = 'UPDATE_CURATOR_GROUP_USER_REQUEST';
export const UPDATE_CURATOR_GROUP_USER_SUCCESS = 'UPDATE_CURATOR_GROUP_USER_SUCCESS';
export const UPDATE_CURATOR_GROUP_USER_ERROR = 'UPDATE_CURATOR_GROUP_USER_ERROR';

export const DELETE_CURATOR_GROUP_USER_REQUEST = 'REMOVE_CURATOR_GROUP_USER_REQUEST';
export const DELETE_CURATOR_GROUP_USER_SUCCESS = 'REMOVE_CURATOR_GROUP_USER_SUCCESS';
export const DELETE_CURATOR_GROUP_USER_ERROR = 'REMOVE_CURATOR_GROUP_USER_ERROR';

// Curator Group Favorites
export const CREATE_CURATOR_GROUP_FAVORITE_SUCCESS = 'CREATE_CURATOR_GROUP_FAVORITE_SUCCESS';
export const CREATE_CURATOR_GROUP_FAVORITE_ERROR = 'CREATE_CURATOR_GROUP_FAVORITE_ERROR';

export const DELETE_CURATOR_GROUP_FAVORITE_REQUEST = 'DELETE_CURATOR_GROUP_FAVORITE_REQUEST';
export const DELETE_CURATOR_GROUP_FAVORITE_SUCCESS = 'DELETE_CURATOR_GROUP_FAVORITE_SUCCESS';
export const DELETE_CURATOR_GROUP_FAVORITE_ERROR = 'DELETE_CURATOR_GROUP_FAVORITE_ERROR';

// Discover
export const SET_DISCOVER_FILTERS = 'SET_DISCOVER_FILTERS';
export const GET_DISCOVER_CURATORS_AND_GROUPS_SUCCESS = 'GET_DISCOVER_CURATORS_AND_GROUPS_SUCCESS';
export const GET_DISCOVER_CURATORS_AND_GROUPS_ERROR = 'GET_DISCOVER_CURATORS_AND_GROUPS_ERROR';

// Search Results
export const SAVE_SEARCH_RESULTS_CACHE = 'SAVE_SEARCH_RESULTS_CACHE';

// User Products
export const CREATE_USER_PRODUCT_REQUEST = 'CREATE_USER_PRODUCT_REQUEST';
export const CREATE_USER_PRODUCT_SUCCESS = 'CREATE_USER_PRODUCT_SUCCESS';
export const CREATE_USER_PRODUCT_ERROR = 'CREATE_USER_PRODUCT_ERROR';

export const DELETE_USER_PRODUCT_REQUEST = 'DELETE_USER_PRODUCT_REQUEST';
export const DELETE_USER_PRODUCT_SUCCESS = 'DELETE_USER_PRODUCT_SUCCESS';
export const DELETE_USER_PRODUCT_ERROR = 'DELETE_USER_PRODUCT_ERROR';

export const UPDATE_USER_PRODUCT_REQUEST = 'UPDATE_USER_PRODUCT_REQUEST';
export const UPDATE_USER_PRODUCT_SUCCESS = 'UPDATE_USER_PRODUCT_SUCCESS';
export const UPDATE_USER_PRODUCT_ERROR = 'UPDATE_USER_PRODUCT_ERROR';

export const CREATE_PIN_VIA_PRODUCT_REQUEST = 'CREATE_PIN_VIA_PRODUCT_REQUEST';
export const CREATE_PIN_VIA_PRODUCT_SUCCESS = 'CREATE_PIN_VIA_PRODUCT_SUCCESS';
export const CREATE_PIN_VIA_PRODUCT_ERROR = 'CREATE_PIN_VIA_PRODUCT_ERROR';

export const DELETE_PRODUCT_FROM_COLLECTION_REQUEST = 'DELETE_PRODUCT_FROM_COLLECTION_REQUEST';
export const DELETE_PRODUCT_FROM_COLLECTION_SUCCESS = 'DELETE_PRODUCT_FROM_COLLECTION_SUCCESS';
export const DELETE_PRODUCT_FROM_COLLECTION_ERROR = 'DELETE_PRODUCT_FROM_COLLECTION_ERROR';

// Products
export const SET_ACTIVE_PRODUCT = 'SET_ACTIVE_PRODUCT';

export const GET_ACTIVE_PRODUCT_REQUEST = 'GET_ACTIVE_PRODUCT_REQUEST';
export const GET_ACTIVE_PRODUCT_SUCCESS = 'GET_ACTIVE_PRODUCT_SUCCESS';
export const GET_ACTIVE_PRODUCT_ERROR = 'GET_ACTIVE_PRODUCT_ERROR';

export const GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_REQUEST = 'GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_REQUEST';
export const GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_SUCCESS = 'GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_SUCCESS';
export const GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_ERROR = 'GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_ERROR';

// Adding Products
export const GET_SIMPLE_COLLECTIONS_FOR_ADDING_SUCCESS = 'GET_SIMPLE_COLLECTIONS_FOR_ADDING_SUCCESS';
export const GET_SIMPLE_COLLECTIONS_FOR_ADDING_ERROR = 'GET_SIMPLE_COLLECTIONS_FOR_ADDING_ERROR';

// Home
export const syncFeaturedCuratorGroups = () => async dispatch => {
  try {
    const resp = await getCuratorsAndGroupsViaDiscoverAPI({
      featuredOnHomePage: true,
      groupLimit: 20
    });
    return dispatch({
      type: GET_FEATURED_CURATOR_GROUPS_SUCCESS,
      groups: resp.groups
    });
  } catch (error) {
    return dispatch({
      type: GET_FEATURED_CURATOR_GROUPS_ERROR,
      error
    });
  }
};

// Hierachy
export const syncHierarchy = () => async (dispatch, getState) => {
  const lastFetched = getState().shop.lastFetchedIndustries;

  // Only fetch hierarchy if it's been more than 15 minutes
  const useCache = window.__IS_PROD__ && lastFetched && moment().diff(lastFetched, 'minutes') < 15;
  if (useCache) {
    return { type: 'NO_OP' };
  }

  // Fetch hierarchy
  const hierarchy = await fetchHierarchy();
  return dispatch({
    type: GET_SHOP_HIERARCHY_SUCCESS,
    ...hierarchy
  });
};

export const setActiveCurator = curator => ({
  type: SET_ACTIVE_CURATOR,
  curator
});

export const setActiveCuratorGroup = group => ({
  type: SET_ACTIVE_CURATOR_GROUP,
  group
});

export const setAttributableCuratorId = Curator_id => async (dispatch, getState) => {
  /*
    We are setting the globally attributable Curator ID.

    All shop-based affiliate links will now be attributed to this curator.

    You can not be your own attributable curator.
  */
  if (Curator_id === getUserId(getState().user)) {
    return dispatch({
      type: SET_ATTRIBUTABLE_CURATOR_ID,
      Curator_id: null
    });
  }

  return dispatch({
    type: SET_ATTRIBUTABLE_CURATOR_ID,
    Curator_id
  });
};

export const setAttributableCuratorGroupId = CuratorGroup_id => async (dispatch, getState) => {
  /* 
    Specify the globally attributable Curator Group ID. 
    
    All shop-based affiliate links will now be attributed to this group.
  */
  return dispatch({
    type: SET_ATTRIBUTABLE_CURATOR_GROUP_ID,
    CuratorGroup_id
  });
};

export const addRecentlyViewedProduct = product => ({
  type: ADD_RECENTLY_VIEWED_PRODUCT,
  product
});

export const createCuratorGroup = (curatorGroup, options = {}) =>
  withShopperLoginCheck(async (dispatch, getState, newUserObject) => {
    const existingGroups = getCuratorGroups(getState().user);
    try {
      const newGroup = await createCuratorGroupAPI({
        ...curatorGroup,
        User_id: getUserId(getState().user || getUserId(newUserObject)),
        isPrimary: !existingGroups.length
      });
      window.__ADD_EVENT__('Shop - Create Curator Group', { source: options.source || 'Unknown' });
      return dispatch({
        type: CREATE_CURATOR_GROUP_SUCCESS,
        group: newGroup
      });
    } catch (error) {
      return dispatch({
        type: CREATE_CURATOR_GROUP_ERROR,
        error
      });
    }
  });

export const createCuratorGroupFromInstagramZIP = zipUrl =>
  withShopperLoginCheck(async (dispatch, getState, newUserObject) => {
    try {
      const newGroup = await createCuratorGroupFromInstagramZIPAPI(zipUrl, getUserId(getState().user) || getUserId(newUserObject));
      return dispatch({
        type: CREATE_CURATOR_GROUP_SUCCESS,
        group: newGroup
      });
    } catch (error) {
      return dispatch({
        type: CREATE_CURATOR_GROUP_VIA_INSTAGRAM_ZIP_ERROR,
        error
      });
    }
  });

export const createCuratorGroupFromTasteProfile = num_curators =>
  withShopperLoginCheck(async (dispatch, getState, newUserObject) => {
    try {
      const newGroup = await createCuratorGroupFromTasteProfileAPI({
        User_id: getUserId(getState().user) || getUserId(newUserObject),
        num_curators: num_curators || 25
      });
      return dispatch({
        type: CREATE_CURATOR_GROUP_SUCCESS,
        group: newGroup
      });
    } catch (error) {
      return dispatch({
        type: CREATE_CURATOR_GROUP_ERROR,
        error
      });
    }
  });

export const duplicateCuratorGroup = group =>
  withShopperLoginCheck(async (dispatch, getState, newUserObject) => {
    try {
      const newGroup = await duplicateCuratorGroupAPI(group, getUserId(getState().user) || getUserId(newUserObject));
      return dispatch({
        success: true,
        type: DUPLICATE_CURATOR_GROUP_SUCCESS,
        group: newGroup
      });
    } catch (error) {
      return dispatch({
        type: DUPLICATE_CURATOR_GROUP_ERROR,
        error
      });
    }
  });

export const updateCuratorGroup = (group, updates) =>
  withShopperLoginCheck(async (dispatch, getState) => {
    dispatch({ type: UPDATE_CURATOR_GROUP_REQUEST, group, updates });
    try {
      const updatedGroup = await updateCuratorGroupAPI(group, updates);
      return dispatch({
        success: true,
        type: UPDATE_CURATOR_GROUP_SUCCESS,
        group: updatedGroup
      });
    } catch (error) {
      return dispatch({
        type: UPDATE_CURATOR_GROUP_ERROR,
        group,
        error
      });
    }
  });

export const deleteCuratorGroup = group =>
  withShopperLoginCheck(async (dispatch, getState) => {
    dispatch({ type: DELETE_CURATOR_GROUP_REQUEST, group });
    try {
      await deleteCuratorGroupAPI(group);
      return dispatch({ type: DELETE_CURATOR_GROUP_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DELETE_CURATOR_GROUP_ERROR,
        error
      });
    }
  });

export const createCuratorGroupUser = curatorGroupUser =>
  withShopperLoginCheck(async (dispatch, getState) => {
    try {
      const newGroupUser = await createCuratorGroupUserAPI(curatorGroupUser);
      return dispatch({
        type: CREATE_CURATOR_GROUP_USER_SUCCESS,
        groupUser: newGroupUser
      });
    } catch (error) {
      return dispatch({
        type: CREATE_CURATOR_GROUP_USER_ERROR,
        error
      });
    }
  });

export const updateCuratorGroupUser = (groupUser, updates) =>
  withShopperLoginCheck(async (dispatch, getState) => {
    dispatch({ type: UPDATE_CURATOR_GROUP_USER_REQUEST, groupUser, updates });
    try {
      const updatedGroupUser = await updateCuratorGroupUserAPI(groupUser, updates);
      return dispatch({
        type: UPDATE_CURATOR_GROUP_USER_SUCCESS,
        groupUser: updatedGroupUser
      });
    } catch (error) {
      return dispatch({
        type: UPDATE_CURATOR_GROUP_USER_ERROR,
        groupUser,
        error
      });
    }
  });

export const deleteCuratorGroupUser = groupUser =>
  withShopperLoginCheck(async (dispatch, getState) => {
    dispatch({ type: DELETE_CURATOR_GROUP_USER_REQUEST, groupUser });
    try {
      await deleteCuratorGroupUserAPI(groupUser);
      return dispatch({ type: DELETE_CURATOR_GROUP_USER_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DELETE_CURATOR_GROUP_USER_ERROR,
        groupUser,
        error
      });
    }
  });

export const createCuratorGroupFavorite = (group, options = {}) =>
  withShopperLoginCheck(async (dispatch, getState, newUserObject) => {
    try {
      const groupFavorite = await createCuratorGroupFavoriteAPI({
        CuratorGroup_id: group.id,
        User_id: getUserId(getState().user) || getUserId(newUserObject)
      });
      window.__ADD_EVENT__('Shop - Create Curator Group Favorite', {
        source: options.source || 'Unknown',
        title: group?.title || 'Unknown'
      });
      return dispatch({
        type: CREATE_CURATOR_GROUP_FAVORITE_SUCCESS,
        groupFavorite
      });
    } catch (error) {
      return dispatch({
        type: CREATE_CURATOR_GROUP_FAVORITE_ERROR,
        error
      });
    }
  });

export const deleteCuratorGroupFavorite = (groupFavorite, options = {}) =>
  withShopperLoginCheck(async (dispatch, getState) => {
    dispatch({ type: DELETE_CURATOR_GROUP_FAVORITE_REQUEST, groupFavorite });
    try {
      window.__ADD_EVENT__('Shop - Delete Curator Group Favorite', {
        source: options.source || 'Unknown',
        title: groupFavorite?.group?.title || 'Unknown'
      });
      await deleteCuratorGroupFavoriteAPI(groupFavorite);
      return dispatch({ type: DELETE_CURATOR_GROUP_FAVORITE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DELETE_CURATOR_GROUP_FAVORITE_ERROR,
        error
      });
    }
  });

// Discover
export const setDiscoverFilters = filters => ({
  type: SET_DISCOVER_FILTERS,
  filters
});

export const getCuratorsAndGroupsViaDiscover = filters => async (dispatch, getState) => {
  try {
    const resp = await getCuratorsAndGroupsViaDiscoverAPI(filters);
    return dispatch({
      success: true,
      type: GET_DISCOVER_CURATORS_AND_GROUPS_SUCCESS,
      groups: resp.groups,
      curators: resp.curators
    });
  } catch (error) {
    return dispatch({
      type: GET_DISCOVER_CURATORS_AND_GROUPS_ERROR,
      error
    });
  }
};

// Search Results
export const saveSearchResultsCache = (key, data) => ({
  type: SAVE_SEARCH_RESULTS_CACHE,
  key,
  data
});

// Products (via UserProducts)
export const createUserProductByProductId = Product_id =>
  withShopperLoginCheck(async (dispatch, getState) => {
    dispatch({ type: CREATE_USER_PRODUCT_REQUEST, Product_id });
    try {
      await createUserProductAPI(
        _.omitBy(
          {
            Product_id,
            User_id: getUserId(getState().user),
            viaCurator_id: getAttributableCuratorId(getState().shop),
            viaCuratorGroup_id: getAttributableCuratorGroupId(getState().shop)
          },
          _.isNil
        )
      );
      return dispatch({
        type: CREATE_USER_PRODUCT_SUCCESS,
        Product_id
      });
    } catch (error) {
      return dispatch({
        type: CREATE_USER_PRODUCT_ERROR,
        Product_id,
        error
      });
    }
  });

export const updateUserProduct = (userProduct, updates) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_USER_PRODUCT_REQUEST, userProduct, updates });
  try {
    const updatedUserProduct = await updateUserProductAPI(userProduct, updates);
    return dispatch({
      success: true,
      type: UPDATE_USER_PRODUCT_SUCCESS,
      userProduct: updatedUserProduct
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_USER_PRODUCT_ERROR,
      userProduct,
      error
    });
  }
};

export const deleteUserProductByProductId = Product_id =>
  withShopperLoginCheck(async (dispatch, getState) => {
    dispatch({ type: DELETE_USER_PRODUCT_REQUEST, Product_id });
    try {
      await deleteUserProductAPI({
        Product_id,
        User_id: getUserId(getState().user)
      });
      return dispatch({ type: DELETE_USER_PRODUCT_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DELETE_USER_PRODUCT_ERROR,
        Product_id,
        error
      });
    }
  });

// Products (via Pins)
export const createPinViaProduct = (product, additionalPinInfo) =>
  withShopperLoginCheck(async (dispatch, getState, newUserObject) => {
    /*
      When a user wants to create a pin via a product.

        product => must have { id }
        additionalPinInfo => can have { Collection_id }
    */
    // Trigger a quick request to ensure the shop shows the product as added
    dispatch({ type: CREATE_PIN_VIA_PRODUCT_REQUEST, Product_id: product.id });
    try {
      await createPinAPI({
        User_id: getUserId(getState().user) || getUserId(newUserObject),
        Product_id: product.id,
        link: product.fallbackUrl,
        title: product.title,
        image: product.image,
        ...additionalPinInfo
      });
      return dispatch({ type: CREATE_PIN_VIA_PRODUCT_SUCCESS, Product_id: product.id });
    } catch (error) {
      return dispatch({ type: CREATE_PIN_VIA_PRODUCT_ERROR, Product_id: product.id, error });
    }
  });

export const deleteProductFromCollection = (Product_id, Collection_id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_PRODUCT_FROM_COLLECTION_REQUEST, Product_id, Collection_id });
  try {
    const resp = await deleteProductFromCollectionAPI(Product_id, Collection_id);
    if (resp.is_final_delete) dispatch({ type: DELETE_USER_PRODUCT_REQUEST, Product_id });
    return dispatch({
      type: DELETE_PRODUCT_FROM_COLLECTION_SUCCESS,
      Product_id,
      Collection_id
    });
  } catch (error) {
    return dispatch({ type: DELETE_PRODUCT_FROM_COLLECTION_ERROR, Product_id, error });
  }
};

// Products
export const setActiveProduct = product => ({
  type: SET_ACTIVE_PRODUCT,
  product
});

export const syncActiveProduct = (Product_id, params = {}) => async (dispatch, getState) => {
  dispatch({ type: GET_ACTIVE_PRODUCT_REQUEST, Product_id });
  try {
    const resp = await getProductAPI(Product_id, getUserId(getState().user) ? { User_id: getUserId(getState().user), ...params } : { ...params });
    return dispatch({
      type: GET_ACTIVE_PRODUCT_SUCCESS,
      product: resp.product
    });
  } catch (error) {
    return dispatch({
      type: GET_ACTIVE_PRODUCT_ERROR,
      error
    });
  }
};

export const syncActiveProductSimilarProducts = Product_id => async (dispatch, getState) => {
  dispatch({ type: GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_REQUEST, Product_id });
  try {
    const similarProducts = await getSimilarProductsAPI(Product_id, getUserId(getState().user) ? { User_id: getUserId(getState().user) } : {});
    return dispatch({
      type: GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_SUCCESS,
      Product_id,
      similarProducts
    });
  } catch (error) {
    return dispatch({
      type: GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_ERROR,
      error
    });
  }
};

export const getSimpleCollectionsForAdding = (params = {}) => async (dispatch, getState) => {
  try {
    const resp = await getSimpleCollectionsForAddingAPI(getUserId(getState().user), params);
    return dispatch({
      type: GET_SIMPLE_COLLECTIONS_FOR_ADDING_SUCCESS,
      collections: resp.collections
    });
  } catch (error) {
    return dispatch({
      type: GET_SIMPLE_COLLECTIONS_FOR_ADDING_ERROR,
      error
    });
  }
};
